<script>
</script>

<div class="train">
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewbox="0 0 2897.12 58.85"
    style="enable-background:new 0 0 2897.12 58.85;"
    xml:space="preserve"
  ><style type="text/css">
    </style>
    <g>
      <rect x="27.9" y="10.3" class="st0" width="78.5" height="25.1"></rect>
      <rect x="136.7" y="10.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="264.4" y="10.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="389.8" y="10.6" class="st0" width="110.7" height="25.1"></rect>
      <rect x="513.3" y="10.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="640.4" y="10.6" class="st0" width="110.7" height="25.1"></rect>
      <rect x="765.2" y="9.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="892.9" y="9.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1018.3" y="10.1" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1141.8" y="9.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1268.9" y="10.1" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1393.1" y="8.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1520.8" y="8.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1646.3" y="8.6" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1769.7" y="8.3" class="st0" width="110.7" height="25.1"></rect>
      <rect x="1896.8" y="8.6" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2021.6" y="7.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2149.3" y="7.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2274.8" y="8.1" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2398.2" y="7.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2525.3" y="8.1" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2651.7" y="10.7" class="st0" width="110.7" height="25.1"></rect>
      <rect x="2782.9" y="11.6" class="st0" width="89.2" height="22.9"></rect>
    </g>
    <g>
      <path
        d="M2654.9,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2691,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H2697c-3.2,0-6-2.6-6-5.9V18.6z
		 M2727.2,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2645.3,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H2656
		C2650.1,3.7,2645.3,8.5,2645.3,14.5z"
      ></path>
      <path
        d="M2529.2,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2565.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2601.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2519.6,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C2524.4,3.7,2519.6,8.5,2519.6,14.5z"
      ></path>
      <path
        d="M2403.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2439.6,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2475.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2393.9,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C2398.7,3.7,2393.9,8.5,2393.9,14.5z"
      ></path>
      <path
        d="M2277.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2314,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2350.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2268.2,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C2273,3.7,2268.2,8.5,2268.2,14.5z"
      ></path>
      <path
        d="M2152.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2188.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2224.4,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2142.6,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C2147.4,3.7,2142.6,8.5,2142.6,14.5z"
      ></path>
      <path
        d="M2026.4,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2062.6,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2098.7,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M2016.9,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C2021.7,3.7,2016.9,8.5,2016.9,14.5z"
      ></path>
      <path
        d="M1900.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1936.9,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1973,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H1979c-3.2,0-6-2.6-6-5.9V18.6z
		 M1891.2,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1896,3.7,1891.2,8.5,1891.2,14.5z"
      ></path>
      <path
        d="M1775.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H1781c-3.2,0-6-2.6-6-5.9V18.6z
		 M1811.2,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1847.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1765.5,14.5v31.8c0,6,4.8,10.7,10.6,10.7H1877c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1770.3,3.7,1765.5,8.5,1765.5,14.5z"
      ></path>
      <path
        d="M1649.4,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1685.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1721.7,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1639.8,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1644.6,3.7,1639.8,8.5,1639.8,14.5z"
      ></path>
      <path
        d="M1523.7,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1559.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1596,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1514.1,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1518.9,3.7,1514.1,8.5,1514.1,14.5z"
      ></path>
      <path
        d="M1398,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H1404c-3.2,0-6-2.6-6-5.9V18.6z
		 M1434.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1470.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1388.4,14.5v31.8c0,6,4.8,10.7,10.6,10.7H1500c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1393.2,3.7,1388.4,8.5,1388.4,14.5z"
      ></path>
      <path
        d="M1272.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1308.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1344.6,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9L1344.6,18.6
		L1344.6,18.6z M1262.8,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1267.5,3.7,1262.8,8.5,1262.8,14.5z"
      ></path>
      <path
        d="M1146.6,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1182.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1218.9,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1137.1,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7h-100.9
		C1141.8,3.7,1137.1,8.5,1137.1,14.5z"
      ></path>
      <path
        d="M1021,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1057.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H1063c-3.2,0-6-2.6-6-5.9V18.6z
		 M1093.2,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M1011.4,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H1022
		C1016.2,3.7,1011.4,8.5,1011.4,14.5z"
      ></path>
      <path
        d="M895.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M931.4,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M967.5,18.6c0-3.2,2.7-5.9,6-5.9H992c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M885.7,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H896.3
		C890.5,3.7,885.7,8.5,885.7,14.5z"
      ></path>
      <path
        d="M769.6,18.6c0-3.2,2.7-5.9,6-5.9H794c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M805.7,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M841.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9L841.8,18.6
		L841.8,18.6z M760,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H770.6
		C764.8,3.7,760,8.5,760,14.5z"
      ></path>
      <path
        d="M643.9,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M680,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H686c-3.2,0-6-2.6-6-5.9V18.6z M716.2,18.6
		c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z M634.3,14.5v31.8
		c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H645C639.1,3.7,634.3,8.5,634.3,14.5z"
      ></path>
      <path
        d="M518.2,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M554.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M590.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M508.6,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H519.3
		C513.4,3.7,508.6,8.5,508.6,14.5z"
      ></path>
      <path
        d="M392.5,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M428.6,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M464.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M382.9,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H393.6
		C387.7,3.7,382.9,8.5,382.9,14.5z"
      ></path>
      <path
        d="M266.8,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z
		 M303,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9V18.6z M339.1,18.6
		c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9H345c-3.2,0-6-2.6-6-5.9V18.6z M257.2,14.5v31.8
		c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5c0-6-4.8-10.7-10.6-10.7H267.9C262,3.7,257.2,8.5,257.2,14.5z"
      ></path>
      <path
        d="M141.1,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5c-3.2,0-6-2.6-6-5.9
		L141.1,18.6L141.1,18.6z M177.3,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5
		c-3.2,0-6-2.6-6-5.9V18.6z M213.4,18.6c0-3.2,2.7-5.9,6-5.9h18.5c3.2,0,5.9,2.6,5.9,5.9v6.8c0,3.2-2.6,5.9-5.9,5.9h-18.5
		c-3.2,0-6-2.6-6-5.9L213.4,18.6L213.4,18.6z M131.6,14.5v31.8c0,6,4.8,10.7,10.6,10.7h100.9c5.9,0,10.6-4.8,10.6-10.7V14.5
		c0-6-4.8-10.7-10.6-10.7H142.2C136.4,3.7,131.6,8.5,131.6,14.5z"
      ></path>
      <path
        d="M2864.8,31.3l-24.2-0.1c-3.7-0.1-6.8-3.1-6.8-6.9v-4.7c0-3.8,3.1-6.8,6.9-6.8h17c1.4,0,2.6,0.7,3.5,1.8l7.1,9.9
		C2870.4,27.4,2868.4,31.4,2864.8,31.3z M2813.2,25.2c0,3.2-2.6,5.9-5.9,5.9h-1c-3.2,0-5.9-2.6-5.9-5.9v-6.6c0-3.2,2.6-5.9,5.9-5.9
		h1c3.2,0,5.9,2.6,5.9,5.9V25.2z M2893.1,42.6l-19.8-30.8c-3.2-5-8.7-8-14.5-8h-77.3c-5.9,0-10.8,4.9-10.8,10.8v31.7
		c0,6,4.9,10.7,10.8,10.7h100.6C2892.4,57,2897.3,49.2,2893.1,42.6z"
      ></path>
      <path
        d="M85.5,18.6c0-3.2,2.6-5.9,5.8-5.9h1c3.2,0,5.8,2.6,5.8,5.9v6.6c0,3.2-2.6,5.9-5.8,5.9h-1c-3.2,0-5.8-2.6-5.8-5.9V18.6z
		 M16.6,57h100.6c5.9,0,10.8-4.8,10.8-10.7V14.6c0-5.9-4.9-10.8-10.8-10.8H39.9c-5.8,0-11.3,3-14.5,8L5.6,42.6
		C1.4,49.2,6.4,57,16.6,57z M30.4,24.4l7.1-9.9c0.9-1.1,2.2-1.8,3.5-1.8h17c3.8,0,6.9,3,6.9,6.8v4.7c0,3.8-3.1,6.8-6.8,6.9
		l-24.2,0.1C30.4,31.4,28.3,27.4,30.4,24.4z"
      ></path>
    </g>
  </svg>
</div>

<style>
  .train {
    position: relative;
    animation: trainAnimation 35s linear infinite;
  }

  .train svg {
    position: absolute;
    height: 40px;
    width: 1964px;
  }

  path {
    fill: var(--blue);
  }
  rect {
    fill: var(--offwhite);
  }

  @keyframes trainAnimation {
    0% {
      transform: translateX(-2000px);
    }
    20% {
      transform: translateX(110%);
    }
    60% {
      transform: translateX(110%);
    }
    80% {
      transform: translateX(-2000px);
    }
    100% {
      transform: translateX(-2000px);
    }
  }

  @media screen and (max-width: 600px) {
    path {
      fill: var(--red);
    }
    rect {
      fill: var(--offwhite);
    }
  }
</style>
