<script>
</script>

<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewbox="0 0 149.9 223.5"
  style="enable-background:new 0 0 149.9 223.5;"
  xml:space="preserve"
><style type="text/css">
    .lt0 {
      fill: var(--white);
    }
    .letter {
      fill: var(--black);
      font-size: 255px;
      font-family: Whirly Birdie;
      font-variation-settings: "wght" 75, "wdth" 53, "ital" 0;
      user-select: none;
    }
  </style>
  <rect x="-1" class="lt0" width="151" height="223.5"></rect>
  <text text-anchor="middle" x="75" y="212" class="letter">A</text>
</svg>

<style>
  svg {
    width: 100%;
  }
</style>
