<script>
</script>

<p class="wb-icon" style="top:5%;left:5%">õ</p>
<p class="wb-icon" style="top:0%;left:-10%;font-size:20vw">õ</p>
<p class="wb-icon" style="top:2.5%;right:-5%;font-size:14vw">õ</p>
<p
  class="wb-icon"
  style="top:55%;right:2%;font-size:7vw;transform:rotate(-30deg)"
>
  M
</p>
<p class="wb-icon" style="bottom:-30%;left:-5%;font-size:22vw;">õ</p>

<style>
  p {
    position: absolute;
    color: #7bc4ec;
    font-size: 10vw;
    transition: font-variation-settings 0.4s;
    font-variation-settings: "anim" 100;
    transform-origin: center;
    display: none;
  }
  @media (hover: hover) {
    p:hover {
      font-variation-settings: "anim" 200;
    }
  }
</style>
